import React from 'react'
import loadable from '@loadable/component'
import styled, {css} from 'styled-components'
import {QueryCache, ReactQueryCacheProvider} from 'react-query'
import {BaseModalBackground, ModalProvider} from 'styled-react-modal'

import {fadeIn, fadeOut} from './constants/animations'
import {mediaQueries} from './constants/breakpoints'

const queryCache = new QueryCache()

const transitionEasing = 'cubic-bezier(.22,.68,0,1.71)'

const ModalBackground = styled(BaseModalBackground)<{isClosing: number}>`
  background-color: transparent;
  animation: ${fadeIn} 1s ${transitionEasing} forwards;
  align-items: flex-start;
  padding-top: 50px;

  ${mediaQueries.fromSmall} {
    padding-top: 100px;
  }

  ${mediaQueries.fromMedium} {
    padding-top: 150px;
  }

  ${(props) =>
    props.isClosing
      ? css`
          animation: ${fadeOut} 2s ${transitionEasing} forwards;
        `
      : ''}
`

const Routes = loadable(() => import(/* webpackChunkName: "home" */ './Routes'))

function App() {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ModalProvider backgroundComponent={ModalBackground}>
        <Routes />
      </ModalProvider>
    </ReactQueryCacheProvider>
  )
}

export default App
